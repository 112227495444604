import { useOutletContext, useParams } from '@remix-run/react';
import { useLocationData, useMatchesData } from '@ui/utils/remix';
import invariant from 'tiny-invariant';
import type { Onboarding } from '~/db/onboarding.server';
import type { executeMyBenefitCurve } from '~/onboarding/mybenefitcurve';
import type { OnboardingSession } from '~/session/onboarding.server';

export const useCurrentStep = (): string => {
	const { step, subStep } = useParams();
	return [step ?? '1', subStep].filter(Boolean).join('_');
};

export type OnboardingLoaderData = {
	onboarding: Awaited<ReturnType<typeof OnboardingSession.requireOnboarding>>;
	results: Awaited<ReturnType<typeof executeMyBenefitCurve>>;
};

export function isOnboarding(onboarding: any): onboarding is Onboarding {
	return (
		onboarding &&
		typeof onboarding?.data === 'object' &&
		typeof onboarding?.assumptions === 'object' &&
		typeof onboarding?.scaleFactors === 'object'
	);
}

export function isOnboardingLoaderData(d: any): d is OnboardingLoaderData {
	return d && isOnboarding(d.onboarding) && typeof d.results === 'object';
}

export const useOnboardingLoaderData = (): OnboardingLoaderData => {
	const data = useLocationData();
	invariant(isOnboardingLoaderData(data), 'Invalid data');
	return data;
};

export const useOnboardingContext = (): {
	startOver: () => void;
} => {
	return useOutletContext();
};

export function useOptionalOnboarding(): Onboarding | undefined {
	const data = useMatchesData({ id: 'root' });
	if (!data || !isOnboarding(data.onboarding)) {
		return undefined;
	}
	return data.onboarding;
}
